import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  ImageField,
  Checkbox,
} from "@pankod/refine";
import { IPost, ICategory, ICompany, ICertificate } from "interfaces";

const getHostnameFromRegex = (url:any) => {
  // run against regex
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1];
}


export const PostList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<ICertificate>({
    syncWithLocation: true,
    initialPageSize: 20,
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // const categoryIds =
  //   tableProps?.dataSource?.map((item) => item.category.id) ?? [];
  // const { data: categoriesData, isLoading } = useMany<ICategory>({
  //   resource: "categories",
  //   ids: categoryIds,
  //   queryOptions: {
  //     enabled: categoryIds.length > 0,
  //   },
  // });

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categories",
  // });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
          width={3}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
               <Table.Column
          dataIndex="type"
          key="type"
          title="Type"
          render={(value) => <TextField value={value} />}
          // defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />

<Table.Column
          dataIndex="orientation"
          key="orientation"
          title="Orientation"
          render={(value) => <TextField value={value} />}
          // defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
<Table.Column
          dataIndex="pass"
          key="pass"
          title="Pass"
          render={(value) => <Checkbox checked={value} disabled={true} />}
          // defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column<ICompany>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={(record.id)} />
              {/* <ShowButton hideText size="small" recordItemId={record.id} /> */}
              {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
