import { AuthProvider } from "@pankod/refine";
import axios, { AxiosRequestConfig } from "axios";

export const TOKEN_KEY = "zordon-auth";

export interface AxiosReturn {
  status: number;
  data: any;
}

// const SendLogin: Promise<AxiosReturn> = async (apiUrl:string, username:string, password:string) => {
//   var payload = { username, password };

//       var config: AxiosRequestConfig = {
//         method: "post",
//         url: apiUrl + "/auth/v1/login",
//         headers: {
//           Accept: "application/json, text/plain, */*",
//           "Accept-Language": "en-US,en;q=0.5",
//           "Accept-Encoding": "gzip, deflate, br",
//           "Content-Type": "application/json;charset=utf-8",
//           Connection: "keep-alive",
//           "Sec-Fetch-Dest": "empty",
//           "Sec-Fetch-Mode": "cors",
//           "Sec-Fetch-Site": "same-site",
//           "Access-Control-Allow-Origin": "*",
//         },
//         data: JSON.stringify(payload),
//       };

//       const { data, status } = await axios(config);
//       return {data, status}
// }

const zordonAuthProvider = (apiUrl: string) => {
  const axiosInstance = axios.create();

  const authProvider: AuthProvider = {
    login: async ({ username, password, company_domain, is_skillomo }) => {
      var get_company_config: AxiosRequestConfig = {
        method: "get",
        url: apiUrl + "/api/v1/company/login-config/" + company_domain,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.5",
          "Accept-Encoding": "gzip, deflate, br",
          "Content-Type": "application/json;charset=utf-8",
          Connection: "keep-alive",
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-site",
          "Access-Control-Allow-Origin": "*",
        },
      };
      if (!is_skillomo) {
        const resCompany = await axios(get_company_config);

        const company_status = resCompany["status"];
        const company_data = resCompany["data"];

        if (company_status === 200) {
          if (company_data["data"]["create_from"] === "codemi") {
            var payload = {
              username,
              password,
              company_id: company_data["data"]["id"],
              from_admin: true,
            };

            var login_config: AxiosRequestConfig = {
              method: "post",
              url: apiUrl + "/auth/v1/login",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Accept-Language": "en-US,en;q=0.5",
                "Accept-Encoding": "gzip, deflate, br",
                "Content-Type": "application/json;charset=utf-8",
                Connection: "keep-alive",
                "Sec-Fetch-Dest": "empty",
                "Sec-Fetch-Mode": "cors",
                "Sec-Fetch-Site": "same-site",
                "Access-Control-Allow-Origin": "*",
              },
              data: JSON.stringify(payload),
            };

            const resLogin = await axios(login_config);
            const login_status = resLogin["status"];
            const login_data = resLogin["data"];

            if (login_status === 200) {
              localStorage.setItem(TOKEN_KEY, login_data.data.token);

              // set header axios instance
              axiosInstance.defaults.headers = {
                Token: `${login_data.data.token}`,
              };

              return Promise.resolve;
            }
          } else {
            return Promise.reject;
          }
          return Promise.reject;
        }
      } else {
        var payload_skillomo = { username, password };

        var login_config: AxiosRequestConfig = {
          method: "post",
          url: apiUrl + "/api/skillomo/post-creator/v1/login",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Accept-Language": "en-US,en;q=0.5",
            "Accept-Encoding": "gzip, deflate, br",
            "Content-Type": "application/json;charset=utf-8",
            Connection: "keep-alive",
            "Sec-Fetch-Dest": "empty",
            "Sec-Fetch-Mode": "cors",
            "Sec-Fetch-Site": "same-site",
            "Access-Control-Allow-Origin": "*",
          },
          data: JSON.stringify(payload_skillomo),
        };

        const resLogin = await axios(login_config);
        const login_status = resLogin["status"];
        const login_data = resLogin["data"];

        if (login_status === 200) {
          localStorage.setItem(TOKEN_KEY, login_data.data.token);

          // set header axios instance
          axiosInstance.defaults.headers = {
            Token: `${login_data.data.token}`,
          };
          return Promise.resolve;
        }else{
          return Promise.reject;
        }
      }
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        axiosInstance.defaults.headers = {
          Token: `${token}`,
        };
        return Promise.resolve();
      }
      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      return Promise.resolve();
    },
  };

  return {
    authProvider,
    axiosInstance,
  };
};

export default zordonAuthProvider;
