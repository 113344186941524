import { UploadFile } from "@pankod/refine";

interface UploadResponse {
    url: string;
    data: UploadResponseData;
}

interface UploadResponseData {
    file_url: string;
}

interface EventArgs<T = UploadResponse> {
    file: UploadFile<T>;
    fileList: Array<UploadFile<T>>;
}

export const normalizeFile = (event: EventArgs) => {
    const { fileList } = event;

    return fileList.map((item) => {
        const { uid, name, type, size, response, percent, status } = item;

        return {
            uid,
            name,
            url: item.url || response?.data?.file_url,
            type,
            size,
            percent,
            status,
        };
    });
};
