
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'


export const IFrame = ({
  children,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null)
  const [portal, setPortal] = useState(null)
  const mountNode =
    contentRef?.contentWindow?.document?.body

  
  return (
    <iframe style={{borderStyle : "none"}} width={1200} height={700} {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}