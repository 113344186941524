import { Refine } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";

import "@pankod/refine/dist/styles.min.css";
import zordonDataProvider from "./zordonDataProvider";
import zordonAuth from "zordonAuthProvider";
import { PostList, PostCreate, PostEdit, PostShow } from "pages/posts";
import { Title } from "./title";
import { LoginPage } from "./pages/login";

function App() {

  const API_URL = (window.location.hostname.includes("zordon-certif.codemi.co.id") === false)
    ? "https://api.staging.pawonmburi.com"
    : "https://api-id.codemi.co.id";

  const { authProvider, axiosInstance } = zordonAuth(API_URL);
  const dataProvider = zordonDataProvider(
    `${API_URL}/api/v1`,
    axiosInstance,
    "certificates"
  );
  return (
    <Refine
      routerProvider={routerProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      LoginPage={LoginPage}
      Title={Title}
      syncWithLocation={true}
      resources={[
        {
          options: {
            label: "Certificates",
          },
          name: "certificate",
          list: PostList,
          create: PostCreate,
          edit: PostEdit,
        },
      ]}
    ></Refine>
  );
}

export default App;
