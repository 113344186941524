import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  useLogin,
  useTranslate,
  Switch
} from "@pankod/refine";

import { Trans } from "react-i18next";

import "./style.less";
import { useState } from "react";
const { Text, Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

const shadeDrop = {
  boxShadow: "1px -1px 16px -3px rgba(0,0,0,0.1)",
  WebkitBoxShadow: "1px -1px 16px -3px rgba(0,0,0,0.1)",
  MozBoxShadow: "1px -1px 16px -3px rgba(0,0,0,0.1)",
};

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const t = useTranslate();

  const { mutate: login } = useLogin<ILoginForm>();
  const [isSkillomo, setIsSkillomo] = useState(false); // State to track the switch

  const CardTitle = (
    <Title level={3} className="layout-title">
      Zordon
    </Title>
  );

  return (
    <AntdLayout style={{ background: "#2a132e" }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={{ maxWidth: "408px", margin: "auto" }}>
            <Card headStyle={{ borderBottom: 0 }} style={{ ...shadeDrop }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login({...values, is_skillomo: isSkillomo});
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  username: "",
                  password: "",
                }}
              >
                <Form.Item
                  name="username"
                  label={t("pages.login.email", "Username")}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@company.codemi.co.id" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("pages.login.password", "Password")}
                  rules={[{ required: true }]}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Form.Item
                  name="company_domain"
                  label={"Company Domain"}
                  rules={[{ required: false }]}
                >
                  <Input size="large" />
                </Form.Item>
                {/* <Form.Item
                  style={{ marginBottom: "12px" }}
                  name={"from_admin"}
                  valuePropName="checked"
                >
                  <Checkbox>from_admin</Checkbox>
                </Form.Item> */}

                <div style={{ display: "flex", gap: '2vh', alignItems: "center", marginBottom: "12px"}}>
                  <Switch onChange={(value) => setIsSkillomo(value)}/>
                  <span>Skillomo</span>
                </div>
                
                <Button type="primary" size="large" htmlType="submit" block>
                  {t("pages.login.signin", "Login")}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
