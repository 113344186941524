import { useEffect, useState } from 'react';
import { IFrame } from './iframe'

interface IPreview {
    value?: string
  }

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

export const PreviewHTML:React.FC<IPreview> = ({value}) => {
    const [curValue, setCurValue] = useState<string|undefined>("")
    useEffect(() => {
     let timer = setTimeout(() => {
        setCurValue(value)
      }, 500 )
      return () => {
        clearTimeout(timer)
      }
    }, [value])
    return  <IFrame> {htmlToReactParser.parse(curValue)} </IFrame>
  }
  